import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import styles from "./styles";
import JsonLD from "../JsonLD";
import HTMLContent from "../Content";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// const JSONld = ({ nextStarts }) => {
//   return (
//     <script type="application/ld+json">
//       {`{
//   "@context": "http://schema.org/",
//   "@type": "ItemList",
//   "name": "On campus beginner coding classes with 1:1 mentor in Berlin",
//   "sameAs": [
//     "https://www.facebook.com/codeacademyberlin",
//     "https://www.linkedin.com/company/codeacademyberlin/",
//     "https://www.instagram.com/codeacademyberlin/?hl=en"
//   ],
//   "@id": "https://www.codeacademyberlin.com",
//   "description": "On-campus coding academy in Berlin. Live classes, small groups, 1:1 mentoring",
//   "itemListElement": [
//     {
//       "@type": "ListItem",
//       "position": 1,
//       "item": {
//         "@type": "Course",
//         "name": "Web Development",
//         "url": "https://www.codeacademyberlin.com/web-development",
//         "description": "Learn how to build full-stack web applications from scratch in 3 to 5 months.",
//         "teaches": [
//           "Coding basics",
//           "JavaScript",
//           "Career coaching",
//           "Web Development",
//           "React",
//           "Front-End",
//           "Full-Stack"
//         ],
//         "financialAidEligible": "Bildungsgutschein",
//         "inLanguage": "en",
//         "coursePrerequisites": ["No prerequisites required"],
//         "offers": [
//           {
//             "@type": "Offer",
//             "category": "Paid",
//             "priceCurrency": "EUR",
//             "price": 7600
//           },
//           {
//             "@type": "Offer",
//             "category": "Free"
//           }
//         ],
//         "provider": {
//           "@type": "Organization",
//           "name": "Code Academy Berlin",
//           "url": "https://www.codeacademyberlin.com"
//         },
//         "hasCourseInstance": [
//           {
//             "@type": "CourseInstance",
//             "name": "Front-End Web Development",
//             "courseMode": ["onsite", "blended"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 63,
//               "startDate": "${nextStarts[0].date}"
//             }
//           },
//           {
//             "@type": "CourseInstance",
//             "name": "Full-Stack Web Development",
//             "courseMode": ["onsite"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 85,
//               "startDate": "${nextStarts[0].date}"
//             }
//           },
//           {
//             "@type": "CourseInstance",
//             "name": "Advanced Full-Stack Web Development",
//             "courseMode": ["onsite"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 105,
//               "startDate":  "${nextStarts[0].date}"
//             }
//           }
//         ]
//       }
//     },
//     {
//       "@type": "ListItem",
//       "position": 2,
//       "item": {
//         "@type": "Course",
//         "name": "Data Science",
//         "url": "https://www.codeacademyberlin.com/data-science",
//         "description": "Learn how to analyze and visualize data for business from scratch in 3 to 5 months.",
//         "teaches": [
//           "Coding basics",
//           "Python",
//           "Career coaching",
//           "Data Science",
//           "Machine Learning"
//         ],
//         "financialAidEligible": "Bildungsgutschein",
//         "inLanguage": "en",
//         "coursePrerequisites": ["No prerequisites required"],
//         "offers": [
//           {
//             "@type": "Offer",
//             "category": "Paid",
//             "priceCurrency": "EUR",
//             "price": 7600
//           },
//           {
//             "@type": "Offer",
//             "category": "Free"
//           }
//         ],
//         "provider": {
//           "@type": "Organization",
//           "name": "Code Academy Berlin",
//           "url": "https://www.codeacademyberlin.com"
//         },
//         "hasCourseInstance": [
//           {
//             "@type": "CourseInstance",
//             "name": "Data Analytics",
//             "courseMode": ["onsite"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 63,
//               "startDate":  "${nextStarts[1].date}"
//             }
//           },
//           {
//             "@type": "CourseInstance",
//             "name": "Advanced Data Analytics",
//             "courseMode": ["onsite"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 85,
//               "startDate":  "${nextStarts[1].date}"
//             }
//           },
//           {
//             "@type": "CourseInstance",
//             "name": "Data Science & Machine Learning",
//             "courseMode": ["onsite"],
//             "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
//             "courseSchedule": {
//               "@type": "Schedule",
//               "duration": "PT6H",
//               "repeatFrequency": "Daily",
//               "repeatCount": 105,
//               "startDate":  "${nextStarts[1].date}"
//             }
//           }
//         ]
//       }
//     }
//   ]
// }
// `}
//     </script>
//   );
// };

const StartCards = ({ course = "all", classes, data, all, jsonLd }) => {
  const { nextStarts } = data.allMarkdownRemark.edges[0].node.frontmatter
  const nextStartsFiltered = nextStarts.filter(start => new Date(start.date) > new Date() && (start.course === course || course === 'all'))
  const [showAll, setShowAll] = useState(2);

  const json = {
    '@context': 'https://schema.org',
    "@type": "ItemList",
    "name": "On campus beginner coding classes with 1:1 mentor in Berlin",
    "sameAs": [
      "https://www.facebook.com/codeacademyberlin",
      "https://www.linkedin.com/company/codeacademyberlin/",
      "https://www.instagram.com/codeacademyberlin/?hl=en"
    ],
    "@id": "https://www.codeacademyberlin.com",
    "description": "On-campus coding academy in Berlin. Live classes, small groups, 1:1 mentoring",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@type": "Course",
          "name": "Web Development",
          "url": "https://www.codeacademyberlin.com/web-development",
          "description": "Learn how to build full-stack web applications from scratch in 3 to 5 months.",
          "teaches": [
            "Coding basics",
            "JavaScript",
            "Career coaching",
            "Web Development",
            "React",
            "Front-End",
            "Full-Stack"
          ],
          "financialAidEligible": "Bildungsgutschein",
          "inLanguage": "en",
          "coursePrerequisites": ["No prerequisites required"],
          "offers": [
            {
              "@type": "Offer",
              "category": "Paid",
              "priceCurrency": "EUR",
              "price": "7600"
            },
            {
              "@type": "Offer",
              "category": "Free"
            }
          ],
          "provider": {
            "@type": "Organization",
            "name": "Code Academy Berlin",
            "url": "https://www.codeacademyberlin.com"
          },
          "hasCourseInstance": [
            {
              "@type": "CourseInstance",
              "name": "Front-End Web Development",
              "courseMode": ["onsite", "blended"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": "63",
                "startDate": nextStartsFiltered[0].date
              }
            },
            {
              "@type": "CourseInstance",
              "name": "Full-Stack Web Development",
              "courseMode": ["onsite"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": "85",
                "startDate": nextStartsFiltered[0].date
              }
            },
            {
              "@type": "CourseInstance",
              "name": "Advanced Full-Stack Web Development",
              "courseMode": ["onsite"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": "105",
                "startDate": nextStartsFiltered[0].date
              }
            }
          ]
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@type": "Course",
          "name": "Data Science",
          "url": "https://www.codeacademyberlin.com/data-science",
          "description": "Learn how to analyze and visualize data for business from scratch in 3 to 5 months.",
          "teaches": [
            "Coding basics",
            "Python",
            "Career coaching",
            "Data Science",
            "Machine Learning"
          ],
          "financialAidEligible": "Bildungsgutschein",
          "inLanguage": "en",
          "coursePrerequisites": ["No prerequisites required"],
          "offers": [
            {
              "@type": "Offer",
              "category": "Paid",
              "priceCurrency": "EUR",
              "price": "7600"
            },
            {
              "@type": "Offer",
              "category": "Free"
            }
          ],
          "provider": {
            "@type": "Organization",
            "name": "Code Academy Berlin",
            "url": "https://www.codeacademyberlin.com"
          },
          "hasCourseInstance": [
            {
              "@type": "CourseInstance",
              "name": "Data Analytics",
              "courseMode": ["onsite"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": "63",
                "startDate": nextStartsFiltered[1]?.date
              }
            },
            {
              "@type": "CourseInstance",
              "name": "Advanced Data Analytics",
              "courseMode": ["onsite"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": "85",
                "startDate": nextStartsFiltered[1]?.date
              }
            },
            {
              "@type": "CourseInstance",
              "name": "Data Science & Machine Learning",
              "courseMode": ["onsite"],
              "location": "Code Academy Berlin, Erich-Weinert-Straße 145, 10409 Berlin, Germany",
              "courseSchedule": {
                "@type": "Schedule",
                "duration": "PT6H",
                "repeatFrequency": "Daily",
                "repeatCount": 105,
                "startDate": nextStartsFiltered[1]?.date
              }
            }
          ]
        }
      }
    ]
  };

  return (
    <Grid container
      justifyContent="center"
      spacing={2}
      className={classes.container}
      alignItems="center">
      {jsonLd && <Helmet >
        <script type='application/ld+json'>{JSON.stringify(json, null, 2)}</script>
      </Helmet>}
      {/* {jsonLd && <JsonLD json={json} />} */}
      {nextStartsFiltered.map(
        (start, i) =>
          i < showAll && (
            <Grid
              item xs={12} md={6}
              container
              justifyContent="center"
              alignContent="center"
              alignItems="stretch"


              key={`${start.course}-${start.date}`}>

              <Card className={classes.card}>
                <CardHeader
                  title={start.fdate}
                  subheader={start.subheader}
                  titleTypographyProps={{
                    fontSize: "2.5rem",
                    align: "center",
                  }}
                  subheaderTypographyProps={{ align: "center" }}
                  className={classes.cardHeader}
                  avatar={<Avatar alt="" src={start.chip.childImageSharp.fixed.src} className={classes.avatar} />}
                />
                <CardContent className={classes.cardContent}>
                  <Typography component="h4" variant="h4" color="textPrimary">
                    {start.title}
                  </Typography>
                  <HTMLContent
                    className={classes.description}
                    content={start.description.childMarkdownRemark.html}
                  />
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                  <Link
                    className={classes.link}
                    to={`/application?course=${start.course}&date=${start.date}`}
                  >
                    <Button variant="contained">
                      Apply for this course
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          )
      )}
      {all && nextStarts.length > 2 && (
        <Grid item xs={12} className={classes.cardContent}>
          {showAll !== nextStarts.length ? (
            <Button
              onClick={() => setShowAll(nextStarts.length)}
              color="primary"
            >
              Show more
            </Button>
          ) : (
            <Button onClick={() => setShowAll(2)} color="inherit">
              Show less
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};
StartCards.propTypes = {
  location: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  data: PropTypes.shape({}),
  all: PropTypes.bool,
  jsonLd: PropTypes.bool,
};
withStyles(styles)(StartCards);

const NextStartsQuery = ({ location, classes, all, course, jsonLd }) => (
  <StaticQuery
    query={graphql`
      query NextStartsQuery {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: ASC }
          filter: { frontmatter: { key: { eq: "nextStarts" } } }
        ) {
          edges {
            node {
              frontmatter {
                nextStarts {
                  course
                  title
                  date
                  fdate(formatString: "DD MMMM YYYY")               
                  chip {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <StartCards course={course} data={data} all={all} jsonLd={jsonLd} location={location} classes={classes} />
    )}
  />
);
export default withStyles(styles)(NextStartsQuery);